import React, { Fragment } from 'react'

const JazzCashPay = () => {
  return (
    <Fragment>
      <h3>JazzCash</h3>
    </Fragment>

  )
}

export default JazzCashPay;