export const MetaPageData = [
    // {
    //     url: '/',
    //     title:'Buy Online Air Tickets &amp; Flight Deals On Cheap Rates - Faremakers',
    //     description:'Faremakers is one of the best traveling Platform. Where you can buy online airline tickets and make your traveling plan at home. We provide services 24/7.',
    //     keywords:'online flight booking, book cheap flights, online booking international flights, online air tickets, international domestic flights, affordable airline tickets, online air tickets, hajj umrah packages.'
    // },
    // {
    //     url: '/searchflightresult',
    //     title:'Search Cheap Flight Tickets – Faremakers',
    //     description:'Search and book cheap flight tickets at faremakers.com and get amazing discounted deals.',
    //     keywords:'Cheap flights, cheap flights booking, search cheap flights, online flights booking, airlines tickets rates.'
    // },
    // {
    //     url: '/flightbooking',
    //     title:'Search Cheap Flight Tickets – Faremakers',
    //     description:'Search and book cheap flight tickets at faremakers.com and get amazing discounted deals.',
    //     keywords:'Cheap flights, cheap flights booking, search cheap flights, online flights booking, airlines tickets rates.'
    // },

    {
        "url": "/",
        "title": "Buy Online Air Tickets Flight Deals On Cheap Rates - Faremakers",
        "description": "Faremakers is one of the best traveling Platform. Where you can buy online airline tickets and make your traveling plan at home. We provide services 24/7.",
        "keywords": "online flight booking, book cheap flights, online booking international flights, online air tickets, international domestic flights, affordable airline tickets, online air tickets, hajj umrah packages.",
        "snacks": []
      },
      {
        "url": "/searchflightresult",
        "title": "Search Cheap Flight Tickets – Faremakers",
        "description": "Search and book cheap flight tickets at faremakers.com and get amazing discounted deals.",
        "keywords": "Cheap flights, cheap flights booking, search cheap flights, online flights booking, airlines tickets rates.",
        "snacks": []
      },
      {
        "url": "/flightbooking",
        "title": "Book Flight",
        "description": "",
        "keywords": "",
        "snacks": []
      },
      {
        "url": "/bookingpayment",
        "title": "Booking Methodes",
        "description": "",
        "keywords": "",
        "snacks": []
      },
      {
        "url": "/contact-us",
        "title": "Contact Us for Air Ticket Booking in Pakistan. View our offices.",
        "description": "Contact Us for doing any kind of flight ticket reservation in Pakistan. Our team is present 24/7 to support you. You can contact any of our offices.",
        "keywords": "",
        "snacks": []
      },
      {
        "url": "/customer-support",
        "title": "Customer Support",
        "description": "Need Help in Flight Tickets or Having Any Problem. Contact Us. Get Answers of Common Questions Related to Flight Booking.",
        "keywords": "",
        "snacks": []
      },
      {
        "url": "/terms-of-service",
        "title": "Terms of Service",
        "description": "Need Help in Flight Tickets or Having Any Problem. Contact Us. Get Answers of Common Questions Related to Flight Booking.",
        "keywords": "",
        "snacks": []
      },
      {
        "url": "/refund-policy",
        "title": "Refund Policy",
        "description": "Need Help in Flight Tickets or Having Any Problem. Contact Us. Get Answers of Common Questions Related to Flight Booking.",
        "keywords": "",
        "snacks": []
      },
      {
        "url": "/terms-and-conditions",
        "title": "Terms and Condition",
        "description": "Need Help in Flight Tickets or Having Any Problem. Contact Us. Get Answers of Common Questions Related to Flight Booking.",
        "keywords": "",
        "snacks": []
      },
      {
        "url": "/banks",
        "title": "Bank Accounts",
        "description": "",
        "keywords": "",
        "snacks": []
      },
      {
        "url": "/blogs/pia-is-increasing-its-flights-to-saudi-arabia",
        "title": "pia is increasing its flights to saudi arabia",
        "description": "abc",
        "keywords": "",
        "snacks": []
      },
      {
        "url": "/flights/cheap-flights-from-lahore-to-london",
        "title": "Cheap Flights From Lahore To London",
        "description": "",
        "keywords": "",
        "snacks": []
      },
      // --------------Domestic Pages Title -------------------
      {
        "url": "/flights/cheap-flights-from-lahore-to-karachi",
        "title": "Cheap Flights From Lahore To Karachi",
        "description": "",
        "keywords": "",
        "snacks": []
      },
      {
        "url": "/flights/cheap-flights-from-lahore-to-islamabad",
        "title": "Cheap Flights From Lahore To Islambad",
        "description": "",
        "keywords": "",
        "snacks": []
      },
      {
        "url": "/flights/cheap-flights-from-lahore-to-peshawar",
        "title": "Cheap Flights From Lahore To Peshawar",
        "description": "",
        "keywords": "",
        "snacks": []
      },
      {
        "url": "/flights/cheap-flights-from-lahore-to-faisalabad",
        "title": "Cheap Flights From Lahore To Faisalabad",
        "description": "",
        "keywords": "",
        "snacks": []
      },
      {
        "url": "/flights/cheap-flights-from-lahore-to-quetta",
        "title": "Cheap Flights From Lahore To Quetta",
        "description": "",
        "keywords": "",
        "snacks": []
      },

      // --------------International Pages Title -------------------
        {
        "url": "/flights/cheap-flights-from-lahore-to-london",
        "title": "Cheap Flights from Lahore to London",
        "description": "",
        "keywords": "",
        "snacks": []
      },
      {
        "url": "/flights/cheap-flights-from-lahore-to-dubai",
        "title": "Cheap Flights from Lahore to Dubai",
        "description": "",
        "keywords": "",
        "snacks": []
      },
      {
        "url": "/flights/cheap-flights-from-lahore-to-toronto",
        "title": "Cheap Flights from Lahore to Toronto",
        "description": "",
        "keywords": "",
        "snacks": []
      },
]